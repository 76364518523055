<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="py-5" elevation="8">
          <h1
            class="text-center primary--text text-h4"
            style="font-family: Kelly Slab !important"
          >
            Planos para o CPF/CNPJ {{ $store.state.cpf }}
          </h1>
          <h4 class="text-center">
            Data:
            {{
              new Date()
                .getDate()
                .toString()
                .padStart(2, "0")
            }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
            {{ new Date().getFullYear() }}
          </h4>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs="12" v-if="mostraConsolidado">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line v-for="(item, i) in consolidado" :key="i">
            <v-list-item-content>
              <div
                class="overline mb-4"
                style="font-family: Kelly Slab !important"
              >
                {{ credor }}
              </div>
              <v-list-item-title class="headline mb-1"
                >A Vista</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Valor R$ {{ item.totalValue | dinheiro }}
                </div>
                <div class="text--primary">*****</div>
              </v-list-item-subtitle>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="negociarConsol(item)"
              >
                Negociar
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostraParcelado">
        <v-card class="mx-auto" max-width="344">
          <v-list-item three-line v-for="(item, i) in parcelado" :key="i">
            <v-list-item-content>
              <div class="overline mb-4">{{ credor }}</div>
              <v-list-item-title class="headline mb-1"
                >Parcelado</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Entrada de R$ {{ item.installmentAmountCharged | dinheiro }}
                </div>
                <div class="text--primary">
                  Mais {{ item.numberOfInstallments }} X de R$
                  {{ item.installmentValue | dinheiro }}
                </div>
              </v-list-item-subtitle>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="negociarParc(item)"
              >
                Negociar
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
      <v-col xs="12" v-if="mostraFatura">
        <v-card class="mx-auto" max-width="344">
          <v-card-title class="headline mb-1">Faturas</v-card-title>
          <v-list-item three-line v-for="(item, i) in faturas" :key="i">
            <v-list-item-content>
              <div class="overline mb-4">{{ credor }}</div>
              <v-list-item-title class="headline mb-1">
                Fatura {{ item.registerId }}</v-list-item-title
              >
              <v-list-item-subtitle>
                <div class="text--primary">
                  Valor R$ {{ item.currentValue | dinheiro }}
                </div>
                <div class="text--primary mb-2">
                  Vencimento Original
                  {{ item.dueDate | dataF }}
                </div>
              </v-list-item-subtitle>
              <v-btn
                text
                color="deep-purple accent-4"
                @click="negociarFatura(item)"
              >
                Negociar
              </v-btn>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Aguarde...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { api } from "@/conf/api";
export default {
  name: "PlanoOiNova",
  data() {
    return {
      dialog: false,
      credor: "",
      mostraConsolidado: false,
      mostraParcelado: false,
      mostraFatura: false,
      faturas: [],
      consolidado: [],
      parcelado: [],
    };
  },
  async beforeMount() {
    this.mostraConsolidado = false;
    this.mostraParcelado = false;
    this.mostraFatura = false;
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
    let divida = this.$store.state.divida_oi_nova;
    let parcelado = [];
    let consolidado = [];
    let faturas = [];
    // console.log("divida", divida.dados.items);
    divida.dados.items.forEach((a) => {
      // console.log("tipo", a.registerType);
      if (a.registerType == "0" || a.registerType == "1") {
        faturas.push(a);
      }
      if (a.registerType == "2" || a.registerType == "3") {
        if (a.numberOfInstallments == "0") {
          consolidado.push(a);
        } else {
          parcelado.push(a);
        }
      }
    });

    this.faturas = faturas;
    this.parcelado = parcelado;
    if (this.parcelado.length > 0) {
      this.mostraParcelado = true;
    }

    this.consolidado = consolidado;
    if (this.consolidado.length > 0) {
      this.mostraConsolidado = true;
    }
    console.log(
      this.consolidado.length,
      this.parcelado.length,
      this.faturas.length,
      this.mostraConsolidado
    );
    if (this.parcelado.length < 1 && this.consolidado.length < 1) {
      return (window.location.href = "https://www.oi.com.br/negociacao/");
    }
    // console.log("f", faturas);
    // console.log("p", parcelado);
    // console.log("c", consolidado);
    // https://www.oi.com.br/negociacao/
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  methods: {
    async negociarFatura(item) {
      this.dialog = true;
      console.log("item-fatura", item);
      let id = item.registerId;
      let meuEmail = window.prompt("Coloque um email...");
      if (!meuEmail) {
        window.alert("Você precisa colocar um email...");
        return;
      }
      await this.$store.dispatch("marcaEmail", meuEmail);
      let body = {
        register: id,
        tipoContato: "EMAIL",
        email: meuEmail,
        telefone: "",
      };
      try {
        let cgc_credor = this.$store.state.divida_atual.cpcgc;
        let cpf = this.$store.state.divida_atual.cpcpfcgc;
        let contrato = this.$store.state.divida_atual.contrato;
        let datarem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
        // /fatura/:cpf?/:contrato?/:cgc?/:datarem?/:sistema?
        let url = `telemanova/oi/fatura/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
        let resposta = await api.post(url, body);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", { item, resp });
      } catch (e) {
        console.log(e);
        return this.$router.push({ name: "ErroPage" });
      }
      // this.$store.dispatch("insereDividaOiNovaResp", dividaOiNova);
      this.dialog = false;
      this.$router.push({ name: "BolOiFatura" });
    },
    async negociarParc(item) {
      this.dialog = true;
      console.log("item-parc", item);
      let id = item.registerId;
      let meuEmail = window.prompt("Coloque um email...");
      if (!meuEmail) {
        window.alert("Você precisa colocar um email...");
        return;
      }
      await this.$store.dispatch("marcaEmail", meuEmail);
      let body = {
        register: id,
        tipoContato: "EMAIL",
        email: meuEmail,
        telefone: "",
      };
      try {
        let cgc_credor = this.$store.state.divida_atual.cpcgc;
        let cpf = this.$store.state.divida_atual.cpcpfcgc;
        let contrato = this.$store.state.divida_atual.contrato;
        let datarem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
        // /oferta/:cpf?/:contrato?/:cgc?/:datarem?/:sistema?
        let url = `telemanova/oi/oferta/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
        let resposta = await api.post(url, body);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", { item, resp });
      } catch (e) {
        console.log(e);
        return this.$router.push({ name: "ErroPage" });
      }
      //this.$router.push({ name: "PlanoOiNova2" });
      this.dialog = false;
      this.$router.push({ name: "BolOiParcelado" });
    },
    async negociarConsol(item) {
      this.dialog = true;
      console.log("item-a vista", item);
      let id = item.registerId;
      let meuEmail = window.prompt("Coloque um email...");
      if (!meuEmail) {
        window.alert("Você precisa colocar um email...");
        return;
      }
      await this.$store.dispatch("marcaEmail", meuEmail);
      let body = {
        register: id,
        tipoContato: "EMAIL",
        email: meuEmail,
        telefone: "",
      };
      try {
        let cgc_credor = this.$store.state.divida_atual.cpcgc;
        let cpf = this.$store.state.divida_atual.cpcpfcgc;
        let contrato = this.$store.state.divida_atual.contrato;
        let datarem = this.$store.state.divida_atual.cpdatrem.substr(0, 10);
        // /oferta/:cpf?/:contrato?/:cgc?/:datarem?/:sistema?
        let url = `telemanova/oi/oferta/${cpf}/${contrato}/${cgc_credor}/${datarem}/site`;
        let resposta = await api.post(url, body);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", { item, resp });
      } catch (e) {
        console.log(e);
        return this.$router.push({ name: "ErroPage" });
      }
      //this.$router.push({ name: "PlanoOiNova2" });
      this.dialog = false;
      this.$router.push({ name: "BolOiAVista" });
    },
  },
};
</script>

<style></style>
